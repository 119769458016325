import { IconButton } from '@mui/material'

import { Icons } from '@/enums'
import { uiStore, useUiState } from '@/store'
import { UiIcon } from '@/ui'

export default function ThemeSwitch() {
  const { paletteMode } = useUiState()

  const togglePaletteMode = () => {
    uiStore.setPaletteMode(paletteMode === 'dark' ? 'light' : 'dark')
  }

  return (
    <IconButton onClick={togglePaletteMode}>
      <UiIcon name={paletteMode === 'dark' ? Icons.Moon : Icons.Sun} size={5} />
    </IconButton>
  )
}
