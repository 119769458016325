import 'ckeditor5/ckeditor5.css'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Box, FormLabel, Stack, StackProps, TextFieldProps, useTheme } from '@mui/material'
import { BalloonEditor, Bold, Essentials, Italic, Link, Markdown, Paragraph } from 'ckeditor5'
import { ChangeEvent, FocusEvent, forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

type Props = Omit<TextFieldProps, 'value'> & {
  value?: string
  errorMessage?: string
  containerProps?: StackProps
}

export const UiRichTextEditor = forwardRef<HTMLInputElement, Props>(
  ({ value, disabled, onBlur, onFocus, onChange, label, id, containerProps }, ref) => {
    const { palette, spacing, typography } = useTheme()
    const editorRef = useRef<BalloonEditor | null>(null)
    const editorToolbarRef = useRef<HTMLInputElement>(null)

    const fieldId = useMemo(() => id ?? `ui-text-field-${uuidv4()}`, [id])

    const handleEditorChange = (_: unknown, editor: BalloonEditor) => {
      const data = editor.getData()
      const syntheticEvent = {
        target: { value: data },
      } as ChangeEvent<HTMLInputElement>
      onChange?.(syntheticEvent)
    }

    const handleEditorBlur = () => {
      const data = editorRef.current?.getData() || ''
      const syntheticEvent = {
        target: { value: data },
      } as FocusEvent<HTMLInputElement>
      onBlur?.(syntheticEvent)
    }

    const handleEditorFocus = () => {
      const data = editorRef.current?.getData() || ''
      const syntheticEvent = {
        target: { value: data },
      } as FocusEvent<HTMLInputElement>
      onFocus?.(syntheticEvent)
    }

    useImperativeHandle(ref, () => editorToolbarRef.current as HTMLInputElement, [])

    return (
      <Stack spacing={2} {...containerProps}>
        <Box component='input' ref={editorToolbarRef} visibility='hidden' position='absolute' />
        {label && <FormLabel htmlFor={fieldId}>{label}</FormLabel>}
        <Stack
          sx={{
            ['& .ck.ck-editor__editable_inline p']: {
              margin: 0,
              ...typography.body3,
            },

            ['& .ck.ck-editor__editable_inline, .ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable)']:
              {
                py: 4,
                px: 3,
                borderColor: palette.action.focus,
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: spacing(2),
              },

            ['& a']: {
              color: 'inherit',
              textDecoration: 'underline',
              textDecorationColo: 'inherit',
            },
          }}
        >
          <CKEditor
            editor={BalloonEditor}
            onReady={editor => {
              editorRef.current = editor
            }}
            data={value}
            disabled={disabled}
            onBlur={handleEditorBlur}
            onFocus={handleEditorFocus}
            onChange={handleEditorChange}
            config={{
              plugins: [Bold, Italic, Paragraph, Essentials, Link, Markdown],
              toolbar: ['bold', 'italic', '|', 'link'],
            }}
          />
        </Stack>
      </Stack>
    )
  },
)
