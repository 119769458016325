import { styled } from '@mui/material'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  width: 1,
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
})

export default VisuallyHiddenInput
