import { Stack, StackProps, useTheme } from '@mui/material'

export default function UiContainer(props: StackProps) {
  const { breakpoints } = useTheme()

  return (
    <Stack
      {...props}
      sx={{
        px: 4,
        // TODO: Remove minWidth when mobile layout is implemented
        minWidth: 960,
        maxWidth: 1064,
        width: '100%',
        mx: 'auto',
        [breakpoints.down('md')]: { py: 4 },
        ...props.sx,
      }}
    />
  )
}
