import { time, type TimeDate } from '@distributedlab/tools'
import { FormLabel, Stack, useTheme } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { forwardRef, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

type Props = Omit<DatePickerProps<TimeDate>, 'onChange'> & {
  errorMessage?: string
  onChange?: (v: string) => void
}

// FIXME: to make this component be controlled - value shouldn't be undefined || null,
//  but if it is - we can't show user empty input
const UiDatePicker = forwardRef<HTMLInputElement, Props>(
  ({ errorMessage, label, ...rest }: Props, ref) => {
    const { palette } = useTheme()
    const fieldId = useMemo(() => `ui-date-picker-${uuidv4()}`, [])

    const minDate = useMemo(() => {
      if (!rest.minDate) return undefined

      return time(rest.minDate).utc().dayjs
    }, [rest])

    const maxDate = useMemo(() => {
      if (!rest.maxDate) return undefined

      return time(rest.maxDate).utc().dayjs
    }, [rest])

    const value = useMemo(() => {
      if (!rest.value) return undefined

      return time(rest.value).utc().dayjs
    }, [rest.value])

    const handleChange = useCallback(
      (v: TimeDate) => {
        if (!v) return

        rest.onChange?.(time(v).utc().format())
      },
      [rest],
    )

    const pickerProps = useMemo<DatePickerProps<TimeDate>>(() => {
      return {
        ...rest,
        inputRef: ref,
        value: value || time().dayjs,
        onChange: v => handleChange(v),
        minDate,
        maxDate,
        timezone: 'UTC',

        slotProps: {
          textField: {
            error: !!errorMessage,
            helperText: errorMessage,
          },
        },
      }
    }, [errorMessage, handleChange, maxDate, minDate, ref, rest, value])

    return (
      <Stack spacing={2}>
        {label && <FormLabel htmlFor={fieldId}>{label}</FormLabel>}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']} sx={{ p: 0, overflow: 'visible' }}>
            <DatePicker
              {...pickerProps}
              sx={{
                ...pickerProps.sx,
                '.MuiIconButton-root': {
                  color: palette.text.secondary,
                  pr: 2,
                  '&:hover': {
                    color: palette.text.primary,
                  },
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Stack>
    )
  },
)

export default UiDatePicker
