import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UserAvatar } from '@/common'
import { Icons, RoutePaths } from '@/enums'
import { authStore } from '@/store'
import { UiIcon } from '@/ui'

export default function ProfileMenu() {
  const { palette, spacing } = useTheme()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const menuItemSx: SxProps = { py: 2.5, px: 4 }

  return (
    <>
      <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <UserAvatar />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='profile-menu'
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        MenuListProps={{ sx: { width: spacing(60) } }}
      >
        <Stack direction='column' p={4} alignItems='center'>
          <UiIcon name={Icons.User} size={6} />
          <Stack direction='row' mt={3} spacing={2}>
            <Typography
              variant='subtitle4'
              overflow='hidden'
              textOverflow='ellipsis'
              maxWidth={spacing(40)}
            >
              Admin
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <MenuItem
          sx={menuItemSx}
          onClick={() => {
            authStore.signOut()
            navigate(RoutePaths.SignIn)
          }}
        >
          <ListItemIcon>
            <UiIcon name={Icons.Logout} size={5} sx={{ color: palette.error.main }} />
          </ListItemIcon>
          <Typography variant='buttonMedium' color={palette.error.main}>
            Sign out
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
