import { FormLabel, Stack, StackProps, TextField, type TextFieldProps } from '@mui/material'
import { forwardRef, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

type Props = TextFieldProps & {
  errorMessage?: string
  containerProps?: StackProps
}

const UiTextField = forwardRef(
  ({ id, errorMessage, containerProps, label, ...rest }: Props, ref) => {
    const fieldId = useMemo(() => id ?? `ui-text-field-${uuidv4()}`, [id])

    return (
      <Stack spacing={2} {...containerProps}>
        {label && <FormLabel htmlFor={fieldId}>{label}</FormLabel>}
        <TextField
          {...rest}
          id={fieldId}
          inputRef={ref}
          error={!!errorMessage}
          helperText={errorMessage || rest.helperText}
        />
      </Stack>
    )
  },
)

export default UiTextField
