import { CssBaseline, ThemeProvider } from '@mui/material'
import { FC, HTMLAttributes, memo, useEffect, useMemo } from 'react'
import { RouterProvider } from 'react-router-dom'

import { ToastsManager } from '@/contexts'
import { useViewportSizes } from '@/hooks'
import { authStore, useUiState } from '@/store'
import { createTheme } from '@/theme'

import { createRouter } from './router'

const App: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const { paletteMode } = useUiState()
  useViewportSizes()

  const theme = useMemo(() => createTheme(paletteMode), [paletteMode])

  useEffect(() => {
    authStore.verifyToken()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastsManager>
        <RouterProvider router={createRouter()} />
      </ToastsManager>
    </ThemeProvider>
  )
}

export default memo(App)
