import { Stack, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

import { AppNavbar } from '@/common'
import { vh } from '@/theme/helpers'
import { UiContainer } from '@/ui'

const MainLayout = ({ children }: PropsWithChildren) => {
  const { breakpoints } = useTheme()

  return (
    <Stack
      direction='row'
      height={vh(100)}
      width='100%'
      sx={{
        backgroundColor: theme => theme.palette.background.default,

        [breakpoints.down('md')]: {
          flexDirection: 'column',
          gap: 0,
        },
      }}
    >
      <AppNavbar />
      <Stack overflow='auto' flex={1}>
        <UiContainer mx='auto' my={8}>
          {children}
        </UiContainer>
      </Stack>
    </Stack>
  )
}

export default MainLayout
