import { Divider, Stack, Tooltip, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { ProfileMenu, ThemeSwitch } from '@/common'
import { Icons, RoutePaths } from '@/enums'
import { Transitions } from '@/theme/constants'
import { UiIcon } from '@/ui'

export default function AppNavbar() {
  const { palette, breakpoints } = useTheme()

  const navbarItems = useMemo<NavbarLinkProps[]>(
    () => [
      {
        route: RoutePaths.Markets,
        icon: Icons.StackLine,
        activeIcon: Icons.StackFill,
        title: 'Markets',
      },
      {
        route: RoutePaths.Badges,
        icon: Icons.ShieldStarLine,
        activeIcon: Icons.ShieldStarFill,
        title: 'Badges',
      },
    ],
    [],
  )

  return (
    <Stack
      justifyContent='space-between'
      alignItems='center'
      py={6}
      px={4}
      borderRight={1}
      borderColor={palette.divider}
      sx={{
        [breakpoints.down('md')]: {
          flexDirection: 'row',
          py: 4,
        },
      }}
    >
      <Stack
        spacing={4}
        sx={{
          [breakpoints.down('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
          },
        }}
      >
        <Stack component={NavLink} to={RoutePaths.Root} alignItems='center'>
          <UiIcon name={Icons.App} size={8} sx={{ color: palette.text.primary }} />
        </Stack>
        <Divider />
        <Stack
          spacing={4}
          p={1}
          sx={{
            [breakpoints.down('md')]: {
              flexDirection: 'row',
            },
          }}
        >
          {navbarItems.map(({ route, icon, activeIcon, title }, idx) => (
            <NavbarLink key={idx} route={route} icon={icon} activeIcon={activeIcon} title={title} />
          ))}
        </Stack>
      </Stack>

      <Stack
        spacing={4}
        sx={{
          [breakpoints.down('md')]: {
            flexDirection: 'row',
          },
        }}
      >
        <ThemeSwitch />
        <ProfileMenu />
      </Stack>
    </Stack>
  )
}

interface NavbarLinkProps {
  route: RoutePaths
  icon: Icons
  activeIcon: Icons
  title: string
}

const NavbarLink = ({ route, icon, activeIcon, title }: NavbarLinkProps) => {
  const location = useLocation()
  const { palette } = useTheme()

  const isRouteActive = useMemo(() => {
    return location.pathname.startsWith(route)
  }, [location.pathname, route])

  return (
    <NavLink to={route}>
      <Tooltip title={title} placement='right' enterDelay={500}>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            px: 4,
            py: 3,
            borderRadius: 3,
            backgroundColor: isRouteActive ? palette.action.active : 'transparent',
            color: isRouteActive ? palette.common.white : palette.text.primary,
            transition: Transitions.Default,
            '&:hover': {
              backgroundColor: isRouteActive ? palette.action.active : palette.action.hover,
            },
          }}
        >
          <UiIcon
            name={isRouteActive ? activeIcon : icon}
            color={isRouteActive ? palette.text.primary : palette.text.secondary}
            size={5}
          />
        </Stack>
      </Tooltip>
    </NavLink>
  )
}
