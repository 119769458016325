export enum RoutePaths {
  Root = '/',
  SignIn = '/sign-in',

  Markets = '/markets',
  MarketNew = '/markets/new',
  MarketEdit = '/markets/:id/edit',

  Badges = '/badges',
}
